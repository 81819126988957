<template>
  <div class='admin-login-main-wrapper'>
    <div class='admin-login-sub-div-wrapper'>
      <div class='form-wrapper'>
        <div class='login-text-wrapper'>
          <span class='login-text'>Login</span>
        </div>
        <div>
          <a-form
            layout='inline'
            style='display: contents'
            :model='dynamicValidateForm'
            @finish='onAdminLogin'
          >
            <a-form-item
              style='margin: 20px 0px'
              name='username'
              :rules="[
                {
                  required: true,
                  message: 'Please input your username',
                  type: 'email',
                },
              ]"
            >
              <a-input
                v-model:value='dynamicValidateForm.username'
                placeholder='Username / Email'
                style='height: 40px; border-radius: 6px'
              >
                <template #prefix><UserOutlined /></template>
              </a-input>
            </a-form-item>
            <a-form-item
              name='password'
              :rules="[
                {
                  required: true,
                  message: 'Please input your Password',
                },
              ]"
              style='margin: 20px 0px'
            >
              <a-input-password
                type='password'
                v-model:value='dynamicValidateForm.password'
                placeholder='Password'
                style='height: 40px; border-radius: 6px'
              >
                <template #prefix><LockOutlined /></template>
              </a-input-password>
            </a-form-item>
            <div class='remember-me-forget-wrapper'>
              <div>
                <a-checkbox v-model:checked='checked' class='rember-me-check-box'
                  >Remember me</a-checkbox
                >
              </div>
            </div>
            <a-form-item class='sign-in-btn-wrapper'>
              <a-button type='primary' html-type='submit'> Sign In </a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import { message } from 'ant-design-vue';
import { useStore } from 'vuex';
import { UserOutlined, LockOutlined } from '@ant-design/icons-vue';
import services from '../../../services/apis';

export default {
  components: {
    UserOutlined,
    LockOutlined,
  },
  setup() {
    const route = useRouter();
    const store = useStore();
    const dynamicValidateForm = reactive({
      username: '',
      password: '',
    });

    const onAdminLogin = (values) => {
      // console.log(values);
      services
        .adminLogin(values)
        .then((response) => {
          if (response.status === 200) {
            message.success('Login successful');
            route.push('/admin/admin-dashboard');
            store.dispatch('onboardingStore/updateAdminLogin', true);
          }
        })
        .catch((e) => {
          message.error(e.response.data.error.message, 3);
        });
    };

    return {
      // onClickSignIn,
      onAdminLogin,
      dynamicValidateForm,
      // disabled,
    };
  },
};
</script>

<style lang='scss'>
@import '../styles/AdminLogin.scss';
</style>
